import { SessionApiImpl } from "@adora/backend-api";
import { deepEqual } from "fast-equals";
import { AdoraSessionStorageImpl, SessionType } from "./adora-session-storage";
import { debugLog } from "./debug";
import { setupSession } from "./setup-session";
import { WindowSessionStorage } from "./storage";
import { checkAcceptedURLAfterTimeout, urlAccepted } from "./url-accepted";
const startRecording = async () => {
    debugLog("startRecording", `adoraIsRunning: ${window["adoraIsRunning"]}`);
    import("./start-recording")
        .then((bundle) => {
        debugLog("start recording bundle loaded");
        bundle.startRecording({
            sessionType: SessionType.Script,
        });
    })
        .catch((e) => {
        debugLog("start recording bundle load error", e);
    });
};
// 30 minutes
const RATE_LIMIT_RETRY_DURATION_MS = 1000 * 60 * 30;
const adoraStart = (settings) => {
    debugLog("adora start", {
        adoraIsRunning: typeof window.sessionStorage === "object" && window["adoraIsRunning"],
    });
    // Guard against SSR and websites running our snippet outside a browser context
    if (typeof window.sessionStorage !== "object") {
        return;
    }
    if (window["adoraIsRunning"] === true) {
        return;
    }
    window["adoraIsRunning"] = true;
    const storage = new AdoraSessionStorageImpl(SessionType.Script, new WindowSessionStorage());
    const lastRateLimitedTimestamp = storage.lastRateLimitedTimestamp;
    if (lastRateLimitedTimestamp !== undefined) {
        // If it has been greater than x duration since we last got rate limited then try again, otherwise return
        if (Date.now() - lastRateLimitedTimestamp < RATE_LIMIT_RETRY_DURATION_MS) {
            return;
        }
        storage.clearLastRateLimitedTimestamp();
    }
    const session = storage.getSession();
    if (session) {
        session.settings.uid = settings.uid;
        // Any user cohort change will be passed into existing session
        const lastUserCohorts = structuredClone(session.settings.userCohorts);
        if (!deepEqual(lastUserCohorts, settings.userCohorts)) {
            session.settings.userCohorts = settings.userCohorts;
            storage.setSession(session);
        }
        debugLog("start recording for existing session");
        return startRecording();
    }
    let started = false;
    const start = () => {
        debugLog("start", { started });
        // Ensure starting is idempotent (start is called only once).
        if (started) {
            return;
        }
        started = true;
        setupSession(SessionType.Script, settings).then((result) => {
            if (result.type === "error") {
                if (result.code === "RateLimitExceeded") {
                    storage.setLastRateLimitedTimestamp(Date.now());
                }
                return;
            }
            return startRecording();
        });
    };
    debugLog("launching session preload");
    new SessionApiImpl(settings.backendUrl)
        .sessionPreload({ orgId: settings.orgId })
        .then((preload) => {
        debugLog("session preload resolved");
        let previousUrl = window.location.href;
        if (preload) {
            if (preload.error) {
                debugLog("error preloading", preload.error);
                return;
            }
            if ("scheduledDowntime" in preload.data) {
                debugLog("scheduled downtime; exiting");
                return;
            }
            const { organization } = preload.data;
            debugLog("session preload success");
            if (urlAccepted({
                urlStr: previousUrl,
                blockedPaths: organization.blockedPaths,
                allowedPaths: organization.allowedPaths,
                pathConfig: organization.pathConfig,
            })) {
                debugLog("at an unblocked URL, starting adora");
                start();
            }
            else {
                debugLog("at an blocked URL, waiting for unblocked URL to start adora");
                // Listen for mutations until we are at an accepted URL.
                const observer = new MutationObserver(function (_, observer) {
                    if (previousUrl != window.location.href) {
                        const thisHref = window.location.href;
                        const accepted = urlAccepted({
                            urlStr: thisHref,
                            blockedPaths: organization.blockedPaths,
                            allowedPaths: organization.allowedPaths,
                            pathConfig: organization.pathConfig,
                        });
                        debugLog(`url changed detected during blocked state, status: ${!!accepted}`);
                        if (accepted) {
                            debugLog(`scheduling adora to start`);
                            checkAcceptedURLAfterTimeout({
                                organization,
                                onSuccess: () => {
                                    // Calling start() is idempotent.
                                    start();
                                    observer.disconnect();
                                },
                            });
                            return;
                        }
                        previousUrl = thisHref;
                    }
                });
                observer.observe(document, { subtree: true, childList: true });
            }
        }
    });
};
window.adoraStart = adoraStart;
export default adoraStart;
