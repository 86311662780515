export function unreachable(_) {
    throw new Error(`This code path should be unreachable: ${_}`);
}
export function never() {
    throw new Error(`This code path should be unreachable`);
}
export function expectDefined(input) {
    if (input === null || input === undefined) {
        throw new Error("expected input to be defined");
    }
    return input;
}
